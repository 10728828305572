<template>
    <div class="request-cargo-issue">

        <div class="field">
            <label for="f1" class="label">{{ lang.awb_number }}</label>
            <input type="text" id="f1" placeholder="***-********"
                   class="number-awb"
                   v-mask="'NNN-########'"
                   v-model="form.awb">
        </div>

        <div class="flex-row">
            <div class="w50">
                <div class="field">
                    <label for="" class="label">{{ lang.date_issue }}</label>
                    <date-picker v-model="form.date_issue"
                                 :input-attr="{ id: 'f3'}"
                                 :editable="false"
                                 lang="ru"
                                 :placeholder="lang.date_issue"
                                 :format="dateFormat"
                                 valueType="format"></date-picker>
                </div>
            </div>
            <div class="w50">
                <div class="field w100">
                    <label for="" class="label">{{ lang.transport_number }}</label>
                    <input type="text" v-model="form.transport_number" :placeholder="lang.transport_number">
                </div>
            </div>
        </div>


        <div class="flex-row">
            <div class="w50">
                <div class="field">
                    <label for="" class="label">{{ lang.driver_name }}</label>
                    <input type="text" v-model="form.driver_name" :placeholder="lang.driver_name">
                </div>
            </div>
            <div class="w50">
                <div class="field w100">
                    <label for="" class="label">{{ lang.driver_phone }}</label>
                    <input type="text"
                           v-mask="phone_mask"
                           v-model="form.driver_phone" :placeholder="lang.driver_phone">
                </div>
            </div>
        </div>

        <div class="flex-row">
            <div class="field w100">
                <label for="" class="label">{{ lang.company }}</label>
                <input type="text" v-model="form.company" :placeholder="lang.company">
            </div>
        </div>
        <div class="flex-row">
            <div class="field w100">
                <label for="" class="label">{{ lang.company_contact }}</label>
                <input type="text" v-model="form.company_contact" :placeholder="lang.company_contact">
            </div>
        </div>

        <div class="flex-row">
            <div class="w50">
                <div class="field">
                    <label for="" class="label">{{ lang.company_email }}</label>
                    <input type="text" v-model="form.company_email" :placeholder="lang.company_email">
                </div>
            </div>
            <div class="w50">
                <div class="field w100">
                    <label for="" class="label">{{ lang.company_phone }}</label>
                    <input type="text"
                           v-mask="phone_mask"
                           v-model="form.company_phone"
                           :placeholder="lang.company_phone">
                </div>
            </div>
        </div>
<!--        v-mask="'+7 (###) ###-##-##'"-->

        <div class="flex-row align-center mt15">
            <div class="buttons">
                <button :class="['button primary', {'loader-active': isLoad}]" @click="sendForm">
                    {{ lang.submit_button }}
                    <div class="loader">
                        <img src="/images/loader-white.svg" alt="">
                    </div>
                </button>
            </div>
            <div class="result" v-if="formResult !== null">
                {{ formResult }}
            </div>

        </div>

        <div class="error" v-if="formError">
            {{ formError }}
        </div>

        <div class="information">
            * {{ lang.help }}
        </div>
    </div>
</template>

<script>
export default {
    name: "RequestCargoIssue",
    props: {
        lang: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            formError: null,
            formResult: null,
            isLoad: false,

            //phone_mask: [/\+?\d/, ' (', /\d/, /\d/, /\d/, ') ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            phone_mask: '+7 (###) ###-##-#####',
            dateFormat: 'DD.MM.YYYY',

            form: {
                awb: null,
                company: null,
                date_issue: null,
                transport_number: null,
                driver_name: null,
                driver_phone: null,
                company_email: null,
                company_phone: null,
                company_contact: null,
            },

            requiredFields: [
                'awb',
                'company',
                'driver_name',
                'transport_number',
                'company_contact',
                'company_phone',
            ],
        }
    },
    created() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);

        if (params.get("awb")) {
            this.form.awb = params.get("awb")
        }
    },
    methods: {
        clearForm() {
            for (let key in this.form) {
                this.form[key] = null
            }
        },
        sendForm () {
            if (this.isLoad) return
            this.formResult = null
            this.formError = null

            let errors = []
            this.requiredFields.map(key => {
                if (!this.form[key]) {
                    errors.push(`${this.lang.empty_field} - ${this.lang[key]}`)
                }
            })

            if (errors.length > 0) {
                this.formError = errors.join('. ')
                return;
            }

            this.isLoad = true

            axios.post('/request-cargo-issue', {
                form: this.form
            })
            .then(({data}) => {
                if (data.success) {
                    this.formResult = data.message
                    this.clearForm()
                } else {
                    this.formError = data.message
                }

            })
            .finally(() => {
                this.isLoad = false
            })
        }
    },
}
</script>

<style scoped>

</style>
