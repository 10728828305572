<template>
    <div class="form-generator">

        <h3>{{ lang.proxy }}</h3>
        <div class="flex-row flex-wrap">
            <div v-for="(field, index) in form[0]" :key="'block1-'+index"
                 :class="['field', field.class ? field.class : '']">
                <label class="label">{{ field.title }}</label>

                <input v-if="['text', 'number'].includes(field.type)"
                       :type="field.type"
                       :placeholder="field.title"

                       v-mask="field.mask ? field.mask : ''"
                       v-model="field.value">

                <date-picker v-if="field.type === 'date'"
                             v-model="field.value"
                             :input-attr="{}"
                             :editable="false"
                             lang="ru"
                             :placeholder="field.title"
                             :format="dateFormat"
                             valueType="format"></date-picker>
            </div>
        </div>



        <h3>{{ lang.proxy_in }}</h3>
        <div class="flex-row flex-wrap">
            <div v-for="(field, index) in form[1]" :key="'block1-'+index"
                 :class="['field', field.class ? field.class : '']">
                <label class="label">{{ field.title }}</label>

                <input v-if="['text', 'number'].includes(field.type)"
                       :type="field.type"
                       :placeholder="field.title"
                       v-mask="field.mask ? field.mask : ''"
                       v-model="field.value">

                <date-picker v-if="field.type === 'date'"
                             v-model="field.value"
                             :input-attr="{}"
                             :editable="false"
                             lang="ru"
                             :placeholder="field.title"
                             :format="dateFormat"
                             valueType="format"></date-picker>
            </div>
        </div>

        <h3>{{ lang.proxy_out }}</h3>
        <div class="checkbox-labels">
            <label>
                <input type="radio" name="who" v-model="fieldsWho" value="3">
                {{ lang.person }}
            </label>
            <label>
                <input type="radio" name="who" v-model="fieldsWho" value="2">
                {{ lang.company }}
            </label>
        </div>
        <div class="flex-row flex-wrap">
            <div v-for="(field, index) in form[fieldsWho]" :key="'block1-'+index"
                 :class="['field', field.class ? field.class : '']">
                <label class="label">{{ field.title }}</label>

                <input v-if="['text', 'number'].includes(field.type)"
                       :type="field.type"
                       :placeholder="field.title"
                       :class="field.class ? field.class : ''"
                       v-mask="field.mask ? field.mask : ''"
                       v-model="field.value">

                <date-picker v-if="field.type === 'date'"
                             v-model="field.value"
                             :input-attr="{}"
                             :editable="false"
                             lang="ru"
                             :placeholder="field.title"
                             :format="dateFormat"
                             valueType="format"></date-picker>
            </div>
        </div>

        <div class="flex-row align-center mt15">
            <div class="buttons">
                <button :class="['button primary', {'loader-active': isLoad}]" @click="sendForm">
                    {{ lang.submit_button }}
                    <div class="loader">
                        <img src="/images/loader-white.svg" alt="">
                    </div>
                </button>
            </div>
            <div class="result" v-if="formResult !== null">
                {{ formResult }}
            </div>

        </div>

        <div class="error" v-if="formError">
            {{ formError }}
        </div>

        <div class="information">
            * {{ lang.help }}
        </div>
    </div>
</template>

<script>
export default {
    name: "GeneratorProxy",
    props: {
        lang: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            formError: null,
            formResult: null,
            isLoad: false,

            //phone_mask: [/\+?\d/, ' (', /\d/, /\d/, /\d/, ') ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            phone_mask: '+7 (###) ###-##-#####',
            dateFormat: 'DD.MM.YYYY',

            fieldsWho: 2,

            form: [
                [
                    { id: 'document_number', title: '', type: 'text', value: null, class: 'w33' },
                    { id: 'date_issue', title: '', type: 'date', value: null, class: 'w33' },
                    { id: 'location_issue', title: '', type: 'text', value:  null, class: 'w33' },
                    { id: 'proxy_period', title: '', type: 'date', value: null, class: 'w50' },
                    { id: 'awb_number', title: '', type: 'text', value: null, class: 'w50' },
                ],
                [
                    { id: 'company_client_name', title: '', type: 'text', value: null, class: 'w100' },
                    { id: 'company_client_address', title: '', type: 'text', value:  null, class: 'w100' },
                    { id: 'company_client_ogrn', title: '', type: 'text', value: null, class: 'w50' },
                    { id: 'company_client_inn', title: '', type: 'text', value: null, class: 'w50' },
                    { id: 'company_client_person_position', title: '', type: 'text', value: null, class: 'w50' },
                    { id: 'company_client_person_name', title: '', type: 'text', value: null, class: 'w50' },
                    { id: 'company_client_person_position_document', title: '', type: 'text', value: null, class: 'w50' },
                ],
                [
                    { id: 'company_recipient_name', title: '', type: 'text', value: null, class: 'w100' },
                    { id: 'company_recipient_address', title: '', type: 'text', value: null, class: 'w100' },
                    { id: 'company_recipient_ogrn', title: '', type: 'text', value: null, class: 'w50' },
                    { id: 'company_recipient_inn', title: '', type: 'text', value: null, class: 'w50' },
                ],
                [
                    { id: 'company_person_name', title: '', type: 'text', value: null, class: 'w100' },
                    { id: 'company_person_document_type', title: '', type: 'text', value: null, class: 'w50' },
                    { id: 'company_person_document_number', title: '', type: 'text', value: null, class: 'w50' },
                    { id: 'company_person_address', title: '', type: 'text', value: null, class: 'w100' },
                ],
            ],
            requiredFields: [

            ],
            cookieName: 'proxy-generator'
        }
    },
    watch: {
        form: {
            deep: true,
            handler() {
                let form = {}
                this.form.map(el => {
                    el.map(item => {
                        form[item.id] = item.value
                    })
                })
                this.$cookies.set(this.cookieName, form)
            }
        },
    },
    created() {
        this.form.map(el => {
            el.map(item => {
                item.title = this.lang[item.id]
            })
        })

        let form = this.$cookies.get(this.cookieName)
        if (form) {
            this.form.map(el => {
                el.map(item => {
                    item.value = form[item.id]
                })
            })
        }
    },

    methods: {
        clearForm() {
            this.form.map(el => {
                el.map(item => {
                    item.value = null
                })
            })
        },
        sendForm () {
            if (this.isLoad) return
            this.formResult = null
            this.formError = null

            let data_form = {
                type: this.fieldsWho === 2 || this.fieldsWho === '2' ? 'company' : 'person'
            }
            this.form.map(el => {
                el.map(item => {
                    data_form[item.id] = item.value
                })
            })

            let errors = []
            this.requiredFields.map(key => {
                if (!data_form[key]) {
                    errors.push(`${this.lang.empty_field} - ${this.lang[key]}`)
                }
            })

            if (errors.length > 0) {
                this.formError = errors.join('. ')
                return;
            }

            this.isLoad = true

            axios.post('/proxyGenerator', {
                form: data_form
            }, { responseType: 'blob' })
            .then((response) => {

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Бланк доверенности.docx');
                document.body.appendChild(link);
                link.click();
                this.$cookies.remove(this.cookieName)
            })
            .finally(() => {
                this.isLoad = false
            })
        }
    },
}
</script>

<style scoped>

</style>
