<template>
    <div class="form-generator">

        <h3>{{ lang.form_generator }}</h3>

        <div class="flex-row flex-wrap">
            <div v-for="(field, index) in form" :key="'block1-'+index"
                 :class="['field', field.class ? field.class : '']">
                <label class="label">{{ field.title }}</label>

                <input v-if="['text', 'number'].includes(field.type)"
                       :type="field.type"
                       :placeholder="field.title"

                       v-mask="field.mask ? field.mask : ''"
                       v-model="field.value">

                <date-picker v-if="field.type === 'date'"
                             v-model="field.value"
                             :input-attr="{}"
                             :editable="false"
                             lang="ru"
                             :placeholder="field.title"
                             :format="dateFormat"
                             valueType="format"></date-picker>
            </div>
        </div>


        <div class="flex-row align-center mt15">
            <div class="buttons">
                <button :class="['button primary', {'loader-active': isLoad}]" @click="sendForm">
                    {{ lang.submit_button }}
                    <div class="loader">
                        <img src="/images/loader-white.svg" alt="">
                    </div>
                </button>
            </div>
            <div class="result" v-if="formResult !== null">
                {{ formResult }}
            </div>

        </div>

        <div class="error" v-if="formError">
            {{ formError }}
        </div>

        <div class="information">
            * {{ lang.help }}
        </div>
    </div>
</template>

<script>
export default {
    name: "GeneratorProxy",
    props: {
        lang: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            formError: null,
            formResult: null,
            isLoad: false,

            //phone_mask: [/\+?\d/, ' (', /\d/, /\d/, /\d/, ') ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            phone_mask: '+7 (###) ###-##-#####',
            dateFormat: 'DD.MM.YYYY',

            form: [
                { id: 'company', title: '', type: 'text', value: null, class: 'w100' },
                { id: 'awb_number', title: '', type: 'text', value: null, class: 'w50' },
                { id: 'phone', title: '', type: 'text', value: null, class: 'w50' },
                { id: 'position', title: '', type: 'text', value: null, class: 'w100' },
                { id: 'name', title: '', type: 'text', value: null, class: 'w100' },
            ],

            requiredFields: [

            ],

            cookieName: 'form-deconsolidation',
        }
    },
    watch: {
        form: {
            deep: true,
            handler() {
                let form = {}
                this.form.map(el => {
                    form[el.id] = el.value
                })
                this.$cookies.set(this.cookieName, form)
            }
        },
    },
    created() {
        this.form.map(el => {
            el.title = this.lang[el.id]
        })

        let form = this.$cookies.get(this.cookieName)
        console.log(form)
        if (form) {
            this.form.map(el => {
                if (form[el.id]) {
                    el.value = form[el.id]
                }
            })
        }
    },
    methods: {
        clearForm() {
            this.form.map(el => {
                el.value = null
            })
        },
        sendForm () {
            if (this.isLoad) return
            this.formResult = null
            this.formError = null

            let data_form = {
                type: this.fieldsWho === 2 || this.fieldsWho === '2' ? 'company' : 'person'
            }
            this.form.map(el => {
                data_form[el.id] = el.value
            })

            let errors = []
            this.requiredFields.map(key => {
                if (!data_form[key]) {
                    errors.push(`${this.lang.empty_field} - ${this.lang[key]}`)
                }
            })

            if (errors.length > 0) {
                this.formError = errors.join('. ')
                return;
            }

            this.isLoad = true

            axios.post('/deconsolidationGenerator', {
                form: data_form
            }, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Бланк заявления на расконсолидацию.docx');
                document.body.appendChild(link);
                link.click();
                this.$cookies.remove(this.cookieName)
            })
            .finally(() => {
                this.isLoad = false
            })
        }
    },
}
</script>

<style scoped>

</style>
