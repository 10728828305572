<template>
    <div>
        <div class="field">
            <label for="f1" class="label">{{ lang.awb_number }}</label>
            <div class="number-awb">
                <input type="text" class="first" id="f1" placeholder="***" v-model="form.awb_series">
                <input type="text" class="last" placeholder="********" v-model="form.awb_number">
            </div>
        </div>

        <div class="flex-row dates">
            <div class="field w50">
                <label class="label" for="f2">{{ lang.date_arrival }}</label>
                <date-picker v-model="form.date_arrival" :input-attr="{ id: 'f2'}"
                             :editable="false"
                             :lang="langVal"
                             :placeholder="lang.date_arrival_placeholder"
                             :format="dateFormat"
                             valueType="format"></date-picker>
            </div>

            <div class="field w50">
                <label class="label" for="f3">{{ lang.date_issue }}</label>
                <date-picker v-model="form.date_issue" :input-attr="{ id: 'f3'}"
                             :editable="false"
                             :lang="langVal"
                             :placeholder="lang.date_delivery_placeholder"
                             :format="dateFormat"
                             valueType="format"></date-picker>
            </div>
        </div>

        <div class="flex-row align-center"></div>
            <div class="field w100">
                <label for="f4" class="label">{{ lang.weight }}</label>
                <input type="number" id="f4" v-model="form.weight"
                       :placeholder="lang.weight_placeholder">
            </div>

            <div class="field w100">
                <input type="checkbox" id="f5" v-model="form.cargo_loading">
                <label for="f5">{{ lang.cargo_loading }}</label>
            </div>


        <div class="flex-row align-center mt15">
            <div class="buttons">
                <button :class="['button primary', {'loader-active': isLoad}]" @click="calculate">
                    {{ lang.calculate }}
                    <div class="loader">
                        <img src="/images/loader-white.svg" alt="">
                    </div>
                </button>
            </div>

            <div class="result" v-if="formResult !== null">
                {{ lang.cost_services }} - <span class="price">{{ formResult }} {{ lang.rub }}*</span>.
                <a :href="getNextLink">{{ lang.get_card }}</a>.
            </div>
        </div>

        <div class="error" v-if="formError">
            {{ formError }}
        </div>
        <div class="information">
            * {{ lang.help }}
        </div>

    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
    name: "CalculatorHomePage",
    components: {
        DatePicker
    },
    props: {
        langVal: {
            type: String,
            default: 'ru'
        },
        nextLink: {
            type: String,
            default: '/'
        },
        lang: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            isLoad: false,
            formResult: null,
            formError: null,

            dateFormat: 'DD.MM.YYYY',
            form: {
                date_arrival: null,
                date_issue: null,
                weight: null,
                cargo_loading: null,
                awb_series: null,
                awb_number: null,
            },

            requiredFields: [
                'date_arrival',
                'date_issue',
                'weight',
            ],
        }
    },
    computed: {
        getNextLink() {
            let link = this.nextLink
            if (this.form.awb_number) {
                link += `?awb=${this.form.awb_series}-${this.form.awb_number}`
            }
            return link
        },
    },
    methods: {
        calculate() {
            if (this.isLoad) return
            this.formResult = null
            this.formError = null

            let errors = []
            this.requiredFields.map(key => {
                if (!this.form[key]) {
                    errors.push(`${this.lang.empty_field} - ${this.lang[key]}`)
                }
            })

            if (errors.length > 0) {
                this.formError = errors.join('. ')
                return;
            }

            this.isLoad = true

            axios.post('/calculation-cost', {
                    form: this.form
                })
                .then(({data}) => {
                    this.formResult = data.cost
                })
                .finally(() => {
                    this.isLoad = false
                })

        },
        maskDate(value) {
            let clear = value.replace(RegExp(/[^0-9]/g), '')
            console.log(clear)
            let matches = clear.match(/(\d{2})(\d{2})(\d{4})/)
            console.log(matches)

            let result = `${ matches[1] ?? ''}.${ matches[2] ?? '' }.${ matches[3] }`

            this.form.dateArrival = result
        }
    }
}
</script>

<style scoped>

</style>
