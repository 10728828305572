/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


Vue.use(require('vue-cookies'))

import VueMask from 'v-mask'
Vue.use(VueMask, {   // (!) custom placeholders support requires registration as a plugin to
    placeholders: {
        N: /[A-zА-я0-9]/, // cyrillic letter as a placeholder
    }
})

Vue.component('form-deconsolidation', require('./components/FormDeconsolidation.vue').default);
Vue.component('generator-proxy', require('./components/GeneratorProxy.vue').default);
Vue.component('calculator-home-page', require('./components/CalculatorHomePage.vue').default);
Vue.component('request-cargo-issue', require('./components/RequestCargoIssue.vue').default);
Vue.component('request-cargo-delivery', require('./components/RequestCargoDelivery.vue').default);

Vue.component('font-size', require('./components/FontSize.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


/*
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const router = new VueRouter()
*/

const app = new Vue({
    //router,
    el: '#app',
});
