<template>
    <div class="visible">
        <div class="item big" @click="changeFont('large-text')">А</div>
        <div class="item main" @click="changeFont('normal-text')">А</div>
        <div class="item small" @click="changeFont('small-text')">А</div>
    </div>
</template>

<script>
export default {
    name: "FontSize",
    mounted() {
        let size = this.$cookies.get('font-size')
        if (size) {
            this.setFont(size)
        }
    },
    methods: {
        changeFont(size) {
            this.$cookies.set('font-size', size)
            this.setFont(size)
        },
        setFont(size) {
            let app = document.getElementById('app')
            app.classList.remove('large-text', 'normal-text', 'small-text')
            app.classList.add(size)
        }
    }
}
</script>

<style scoped>

</style>
