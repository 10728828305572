<template>
    <div class="request-cargo-issue">

        <div class="field">
            <label for="f1" class="label">{{ lang.awb }}</label>
            <input type="text" id="f1" placeholder="***-********"
                   class="number-awb"
                   v-mask="'NNN-########'"
                   v-model="form.awb">
        </div>

        <div class="flex-row">
            <div class="field w100">
                <label for="" class="label">{{ lang.contact }}</label>
                <input type="text" v-model="form.contact" :placeholder="lang.contact">
            </div>
        </div>

        <div class="flex-row">
            <div class="w50">
                <div class="field">
                    <label for="" class="label">{{ lang.email }}</label>
                    <input type="text" v-model="form.email" :placeholder="lang.email">
                </div>
            </div>
            <div class="w50">
                <div class="field w100">
                    <label for="" class="label">{{ lang.phone }}</label>
                    <input type="text"
                           v-mask="phone_mask"
                           v-model="form.phone"
                           :placeholder="lang.phone">
                </div>
            </div>
        </div>

        <div class="flex-row">
            <div class="w33">
                <div class="field">
                    <label for="" class="label">{{ lang.date_delivery }}</label>
                    <date-picker v-model="form.date_delivery"
                                 :input-attr="{ id: 'f3'}"
                                 :editable="false"
                                 lang="ru"
                                 :placeholder="lang.date_delivery"
                                 :format="dateFormat"
                                 valueType="format"></date-picker>
                </div>
            </div>
            <div class="w33">
                <div class="field">
                    <label for="" class="label">{{ lang.weight }}</label>
                    <input type="number" v-model="form.weight" :placeholder="lang.weight">
                </div>
            </div>
            <div class="w33">
                <div class="field w100">
                    <label for="" class="label">{{ lang.number_seats }}</label>
                    <input type="number" v-model="form.number_seats" :placeholder="lang.number_seats">
                </div>
            </div>
        </div>


        <div class="flex-row">
            <div class="field w100">
                <label for="" class="label">{{ lang.type }}</label>
                <input type="text" v-model="form.type" :placeholder="lang.type">
            </div>
        </div>

        <div class="flex-row">
            <div class="field w100">
                <label for="" class="label">{{ lang.address }}</label>
                <textarea v-model="form.address" :placeholder="lang.address"></textarea>
            </div>
        </div>

        <div class="flex-row">
            <div class="field w100">
                <label for="" class="label">{{ lang.note }}</label>
                <textarea v-model="form.note" :placeholder="lang.note"></textarea>
            </div>
        </div>


        <div class="flex-row align-center mt15">
            <div class="buttons">
                <button :class="['button primary', {'loader-active': isLoad}]" @click="sendForm">
                    {{ lang.submit_button }}
                    <div class="loader">
                        <img src="/images/loader-white.svg" alt="">
                    </div>
                </button>
            </div>
            <div class="result" v-if="formResult !== null">
                {{ formResult }}
            </div>

        </div>

        <div class="error" v-if="formError">
            {{ formError }}
        </div>

        <div class="information">
            * {{ lang.help }}
        </div>
    </div>
</template>

<script>
export default {
    name: "RequestCargoDelivery",
    props: {
        lang: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            formError: null,
            formResult: null,
            isLoad: false,

            //phone_mask: [/\+?\d/, ' (', /\d/, /\d/, /\d/, ') ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            phone_mask: '+7 (###) ###-##-#####',
            dateFormat: 'DD.MM.YYYY',

            form: {
                awb: null,

                contact: null,
                email: null,
                phone: null,

                weight: null,
                number_seats: null,
                date_delivery: null,

                type: null,
                address: null,
                note: null,

            },

            requiredFields: [
                'awb',
                'email',
                'phone',
                'weight',
                'number_seats',
                'date_delivery',
                'type',
                'address',
                'contact',
            ],
        }
    },
    methods: {
        clearForm() {
            for (let key in this.form) {
                this.form[key] = null
            }
        },
        sendForm () {
            if (this.isLoad) return
            this.formResult = null
            this.formError = null

            let errors = []
            this.requiredFields.map(key => {
                if (!this.form[key]) {
                    errors.push(`${this.lang.empty_field} - ${this.lang[key]}`)
                }
            })

            if (errors.length > 0) {
                this.formError = errors.join('. ')
                return;
            }

            this.isLoad = true

            axios.post('/request-cargo-delivery', {
                form: this.form
            })
            .then(({data}) => {
                if (data.success) {
                    this.formResult = data.message
                    this.clearForm()
                } else {
                    this.formError = data.message
                }

            })
            .finally(() => {
                this.isLoad = false
            })
        }
    },
}
</script>

<style scoped>

</style>
